import React from 'react';
import styled from 'styled-components';
import { HubspotForm } from '@/features/new-landing-page/components/hubspot-form';
import { GlobalStyles } from '@/styles/globalStyles';
import { Header } from '@/features/new-landing-page/components/header';
import { Footer } from '@/features/new-landing-page/components/footer';
import {
  Padding,
  Section,
} from '@/features/new-landing-page/components/section';
import { StrapiTextual } from '@/features/strapi/components/textual';
import { TalkToExpertContent } from '@/features/new-landing-page/pages/talk-to-expert-content';
import { VSpace } from '@/components/spacing';
import { PageWrapper } from '@/features/new-landing-page/pages/homepage';
import { CustomersScroller } from '@/features/new-landing-page/components/customers-scroller';
import { breakpoints } from '@/components/responsive';
import { GradientGlowContainer } from '@/features/new-landing-page/components/gradient-glow-container';

export function TalkToExpert() {
  return (
    <PageWrapper>
      <GlobalStyles />
      <Header />
      <Section>
        <GradientGlowContainer />
        <Padding padding="0 6rem">
          <StrapiTextual
            className="only-mobile"
            {...TalkToExpertContent.form.title}
          />
        </Padding>
        <VSpace className="only-mobile" value={1} />
        <Padding padding="0 3rem">
          <StrapiTextual
            {...TalkToExpertContent.form.subTitle}
            className="only-mobile"
            lineHeight="1.8"
          />
        </Padding>
        <VSpace value={2} className="only-mobile" />
        <TwoColumns>
          <Card>
            <StrapiTextual
              className="only-desktop"
              {...TalkToExpertContent.form.title}
            />
            <VSpace className="only-desktop" value={1} />
            <StrapiTextual
              {...TalkToExpertContent.form.subTitle}
              className="only-desktop"
            />
            <VSpace value={2} className="only-desktop" />
            <HubspotForm
              formId="12741ed6-6c57-481f-8c87-e36253d85734"
              portalId="143410811"
              submitText="Schedule a call"
            />
          </Card>
          <FeaturedReview>
            <StrapiTextual
              className="review-text"
              text={`“${TalkToExpertContent.review.text}”`}
            />
            <VSpace value={4} />
            <div className="columns">
              <img src={TalkToExpertContent.review.image} alt="" />
              <div className="text">
                <StrapiTextual
                  className="author"
                  text={TalkToExpertContent.review.author}
                />
                <StrapiTextual
                  className="job"
                  text={TalkToExpertContent.review.job}
                />
              </div>
            </div>
          </FeaturedReview>
        </TwoColumns>
        <VSpace value={2} />
        <CustomersScroller className="only-desktop" />
        <VSpace value={2} />
      </Section>
      <Footer />
    </PageWrapper>
  );
}

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-gap: 8rem;
  width: 90%;

  ${breakpoints.down(`md`)} {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  min-height: 10rem;
  padding: 8rem 10rem;
  border: 1px solid rgba(151, 151, 151, 0.1);
  border-radius: 3rem;
  background-color: #ffffff;
  box-shadow: 0 4px 50px 0 rgba(0, 0, 0, 0.1),
    0 9px 13px 0 rgba(177, 217, 203, 0.18), 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  && * {
    line-height: 1.8;
  }

  ${breakpoints.down(`md`)} {
    padding: 3rem;
    border-radius: 2rem;
    padding-bottom: 1rem;
  }
`;

const FeaturedReview = styled.div`
  justify-self: center;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .review-text {
    font-size: 2rem;
    font-weight: normal;
    text-align: start;
    line-height: 1.8;
    ${breakpoints.down(`md`)} {
      text-align: center;
      font-size: 1.8rem;
    }
  }

  .columns {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: auto 1fr;
    align-self: flex-start;

    ${breakpoints.down(`md`)} {
      align-self: center;
      margin-left: -3rem;
    }
  }

  .text {
    display: grid;
    grid-gap: 0.5rem;
    justify-items: flex-start;
    align-items: center;
  }

  .author,
  .job {
    font-size: 1.4rem;
    font-weight: 300;
  }

  img {
    background: #969696;
    height: 5rem;
    width: 5rem;
    object-fit: contain;
    border-radius: 50%;
  }
`;
