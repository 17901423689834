import React from 'react';
import { TalkToExpert } from '@/features/new-landing-page/pages/talk-to-expert';

export default TalkToExpert;

export const Head = () => (
  <script
    charSet="utf-8"
    type="text/javascript"
    src="//js-eu1.hsforms.net/forms/embed/v2.js"
  />
);
