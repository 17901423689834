import { StrapiTextualProps } from '@/features/strapi/components/textual';
import { ReviewsContent } from '@/features/new-landing-page/pages/homepage-content';

export const TalkToExpertContent = {
  form: {
    title: {
      size: `3.5rem`,
      weight: `bold`,
      mobileMultiplier: 1,
      text: `Speak with an expert`,
    } as StrapiTextualProps,
    subTitle: {
      size: `1.8rem`,
      mobileMultiplier: 1,
      weight: `normal`,
      text: `Schedule a meeting with one of our experts
to learn how you can scale faster.`,
    } as StrapiTextualProps,
  },
  review: ReviewsContent.reviews.find(
    (review) => review.store === `The Perfect Jean`,
  ),
};
